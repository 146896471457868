module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"the big button","short_name":"tbb","start_url":"/","background_color":"#FFAD00","theme_color":"#FFAD00","display":"minimal-ui","icon":"src/images/the-big-button-icon.jpg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156347142-1","anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"447020506177380"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
